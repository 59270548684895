<template>
  <section class="mt-5">
    <div v-if="loading">
      <Loading />
    </div>
    <form v-else>
      <div class="offmarket">
        <v-row>
          <!-- Images Section -->
          <v-col md="12">
            <div class="mb-3 d-flex justify-between align-items-center">
              <strong>Images</strong>
              <!-- Update Images Button -->
              <v-btn
                class="update-btn mb-2 ml-2"
                @click="updateImagesAct"
                :loading="loadingImagesUpdate"
                small
                color="primary"
              >
                Update Images
              </v-btn>
            </div>
            <!-- Draggable Images List -->
            <draggable class="images" v-model="property.images" @end="onDragEnd">
              <li
                v-for="(ele, index) in property.images"
                :key="ele.id || index"
                class="image-item"
              >
                <span>
                  <v-icon
                    v-if="ele.id"
                    class="closeBtn"
                    @click="deleteImageAct(ele.id)"
                  >
                    mdi-close
                  </v-icon>
                </span>
                <img :src="ele.url" :alt="`Image ${index + 1}`" width="100" />
              </li>
            </draggable>
          </v-col>
          <!-- Upload Image Component -->
          <v-col md="12" cols="12" class="pt-8 mb-3">
            <UploadImage
              property="publish"
              :id="property._id"
              @uploadedAct="refetchData"
            />
          </v-col>
        </v-row>

        <!-- Address Details Section -->
        <div class="section hover-section">
          <h2 class="section-header">Address Details</h2>
          <v-row>
            <v-col v-for="(field, index) in addressFields" :key="index" md="3">
              <div class="flexBox iconText">
                <div>
                  <span>{{ field.label }}</span>
                  <h2>
                    <v-text-field
                      v-if="field.type === 'text'"
                      v-model="property[field.model]"
                      outlined
                      class="hover-field"
                      :readonly="field.locked && fieldsLocked"
                    />
                    <v-select
                      v-else-if="field.type === 'select'"
                      v-model="property[field.model]"
                      :items="field.items"
                      item-text="town"
                      item-value="town"
                      outlined
                      class="hover-field"
                      :readonly="field.locked && fieldsLocked"
                    />
                  </h2>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row justify="center" class="mt-4">
            <v-col cols="auto">
              <v-btn
                class="update-btn"
                @click="updatePropertyAct"
                :loading="loadingUpdate"
                color="primary"
              >
                Update Address Details
              </v-btn>
            </v-col>
          </v-row>
        </div>

        <!-- Financial Details Section -->
        <div class="section hover-section locked-field-section">
          <h2 class="section-header">Financial Details</h2>
          <v-row>
            <v-col cols="12" class="d-flex justify-end mb-2">
              <v-btn
                icon
                @click="fieldsLocked ? showLockModal = true : unlockFields()"
                class="lock-icon"
              >
                <v-icon>{{ fieldsLocked ? 'mdi-lock' : 'mdi-lock-open' }}</v-icon>
              </v-btn>
            </v-col>
            <v-col v-for="(field, index) in financialFields" :key="index" md="3">
              <div
                class="flexBox iconText"
                :class="{ 'locked-field-container': field.locked && fieldsLocked }"
              >
                <div>
                  <span>{{ field.label }}</span>
                  <h2>
                    <v-text-field
                      v-if="field.type === 'text'"
                      :value="getFieldValue(field.model)"
                      @input="updateFieldValue(field.model, $event)"
                      outlined
                      class="hover-field"
                      :readonly="field.locked && fieldsLocked && field.model !== 'opex'"
                    />
                    <v-select
                      v-else-if="field.type === 'select'"
                      v-model="property[field.model]"
                      :items="field.items"
                      item-text="text"
                      item-value="value"
                      outlined
                      class="hover-field"
                      @change="field.onChange ? field.onChange($event) : null"
                      :readonly="field.locked && fieldsLocked"
                    />
                  </h2>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row justify="center" class="mt-4">
            <v-col cols="auto">
              <v-btn
                class="update-btn"
                @click="updatePropertyAct"
                :loading="loadingUpdate"
                color="primary"
              >
                Update Finacial Details
              </v-btn>
            </v-col>
          </v-row>
        </div>

        <!-- Lock Modal -->
        <v-dialog v-model="showLockModal" max-width="400px">
          <v-card>
            <v-card-title>Unlock Fields</v-card-title>
            <v-card-text>
              <v-text-field
                v-model="lockPassword"
                label="Password"
                type="password"
                @keyup.enter="unlockFields"
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="showLockModal = false" class="modal-btn">
                <span>Cancel</span>
              </v-btn>
              <v-btn text @click="unlockFields" class="modal-btn">
                <span>Unlock</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Other Details Section -->
        <div class="section hover-section">
          <h2 class="section-header">Other Details</h2>
          <v-row>
            <v-col v-for="(field, index) in otherFields" :key="index" md="3">
              <div class="flexBox iconText">
                <div>
                  <span>{{ field.label }}</span>
                  <h2>
                    <v-text-field
                      v-if="field.type === 'text'"
                      v-model="property[field.model]"
                      outlined
                      class="hover-field"
                    />
                    <v-select
                      v-else-if="field.type === 'select'"
                      v-model="property[field.model]"
                      :items="field.items"
                      item-text="text"
                      item-value="value"
                      outlined
                      class="hover-field"
                    />
                  </h2>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row justify="center" class="mt-4">
            <v-col cols="auto">
              <v-btn class="update-btn" @click="updatePropertyAct" :loading="loadingUpdate">Update</v-btn>
            </v-col>
          </v-row>
        </div>

         <!-- Additional Details Section -->
          <div class="section hover-section">
            <h2 class="section-header">Additional Details</h2>
            <v-row>
              <!-- Nearest Schools -->
              <v-col md="3">
                <div class="flexBox iconText school-section">
                  <span>Nearest Schools</span>
                  <div class="button-group">
                    <v-btn
                      class="mb-2 add-data-btn"
                      @click="fetchSchoolData"
                      :loading="loadingSchoolData"
                      small
                    >
                      Add Data
                    </v-btn>
                    <v-btn
                      class="mb-2 ml-2 clear-data-btn"
                      @click="clearSchoolData"
                      small
                    >
                      Clear Data
                    </v-btn>
                  </div>
                  <v-row v-if="property.nearestSchool.length > 0">
                    <v-col>
                      <v-text-field
                        v-for="(data, index) in property.nearestSchool"
                        :key="'school-' + index"
                        v-model="data.school"
                        outlined
                        class="hover-field"
                        :readonly="!schoolDataEditable"
                      />
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-for="(data, index) in property.nearestSchool"
                        :key="'distance-' + index"
                        v-model="data.distance"
                        outlined
                        class="hover-field"
                        :readonly="!schoolDataEditable"
                      />
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            
            <!-- Previously Sold - Exact Street -->
              <v-col md="3">
                <div class="flexBox iconText with-spacer">
                  <span>Previously Sold - Exact Street</span>
                  <div class="button-group">
                    <v-btn
                      class="mb-2 add-data-btn"
                      @click="fetchSoldPricesData"
                      :loading="loadingSoldPricesData"
                      small
                    >
                      Add Data
                    </v-btn>
                    <v-btn
                      class="mb-2 ml-2 clear-data-btn"
                      @click="clearSoldPricesData"
                      small
                    >
                      Clear Data
                    </v-btn>
                  </div>
                  <v-text-field
                    v-for="(data, index) in property.streetComparable"
                    :key="index"
                    v-model="property.streetComparable[index]"
                    outlined
                    class="hover-field"
                    :readonly="!soldPricesDataEditable"
                  />
                </div>
              </v-col>
            
            <!-- Previously Sold - Within Postcode Area -->
              <v-col md="3">
                <div class="flexBox iconText with-spacer">
                  <span>Previously Sold - Within Postcode Area</span>
                  <div class="button-group">
                    <v-btn
                      class="mb-2 add-data-btn"
                      @click="fetchAreaSoldPricesData"
                      :loading="loadingAreaSoldPricesData"
                      small
                    >
                      Add Data
                    </v-btn>
                    <v-btn
                      class="mb-2 ml-2 clear-data-btn"
                      @click="clearAreaSoldPricesData"
                      small
                    >
                      Clear Data
                    </v-btn>
                  </div>
                  <v-text-field
                    v-for="(data, index) in property.marketComparable"
                    :key="index"
                    v-model="property.marketComparable[index]"
                    outlined
                    class="hover-field"
                    :readonly="!areaSoldPricesDataEditable"
                  />
                </div>
              </v-col>
            
            <!-- For Sale - Within Local Area -->
            <v-col md="3">
              <div class="flexBox iconText with-spacer">
                <span>For Sale - Within Local Area</span>
                <div class="button-group">
                  <v-btn
                    class="mb-2 add-data-btn"
                    @click="fetchForSaleData"
                    :loading="loadingForSaleData"
                    small
                  >
                    Add Data
                  </v-btn>
                  <v-btn
                    class="mb-2 ml-2 clear-data-btn"
                    @click="clearForSaleData"
                    small
                  >
                    Clear Data
                  </v-btn>
                </div>
                <v-text-field
                  v-for="(data, index) in property.saleComparable"
                  :key="index"
                  v-model="property.saleComparable[index]"
                  outlined
                  class="hover-field"
                  :readonly="!forSaleDataEditable"
                />
              </div>
            </v-col>
            
            <!-- For Rent - Within Local Area -->
            <v-col md="3">
              <div class="flexBox iconText with-spacer">
                <span>For Rent - Within Local Area</span>
                <div class="button-group">
                  <v-btn
                    class="mb-2 add-data-btn"
                    @click="fetchForRentData"
                    :loading="loadingForRentData"
                    small
                  >
                    Add Data
                  </v-btn>
                  <v-btn
                    class="mb-2 ml-2 clear-data-btn"
                    @click="clearForRentData"
                    small
                  >
                    Clear Data
                  </v-btn>
                </div>
                <v-text-field
                  v-for="(data, index) in property.rentComparable"
                  :key="index"
                  v-model="property.rentComparable[index]"
                  outlined
                  class="hover-field"
                  :readonly="!forRentDataEditable"
                />
              </div>
            </v-col>
            
            <!-- Nearest Stations -->
              <v-col md="3">
                <div class="flexBox iconText with-spacer">
                  <span>Nearest Stations</span>
                  <div class="button-group">
                    <v-btn
                      class="mb-2 add-data-btn"
                      @click="fetchNearestStations"
                      :loading="loadingNearestStations"
                      small
                    >
                      Add Data
                    </v-btn>
                    <v-btn
                      class="mb-2 ml-2 clear-data-btn"
                      @click="clearNearestStations"
                      small
                    >
                      Clear Data
                    </v-btn>
                  </div>
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-for="(data, index) in property.nearestStation"
                        :key="'station-' + index"
                        v-model="data.station"
                        outlined
                        class="hover-field"
                        :readonly="!nearestStationsEditable"
                      />
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-for="(data, index) in property.nearestStation"
                        :key="'station-distance-' + index"
                        v-model="data.distance"
                        outlined
                        class="hover-field"
                        :readonly="!nearestStationsEditable"
                      />
                  </v-col>
                </v-row>
              </div>
            </v-col>

            <!-- Deal Benefit -->
            <v-col md="3">
              <div class="flexBox iconText deal-benefit-section">
                <span>Deal Benefit</span>
                <div class="deal-benefit-spacer"></div>
                <v-text-field
                  v-for="(benefit, index) in dealBenefitText"
                  :key="index"
                  :value="benefit"
                  outlined
                  class="hover-field"
                  readonly
                />
              </div>
            </v-col>
          </v-row>
          <v-row justify="center" class="mt-4">
            <v-col cols="auto">
              <v-btn
                class="update-btn"
                @click="updatePropertyAct"
                :loading="loadingUpdate"
                color="primary"
              >
                Update Additional Details
              </v-btn>
            </v-col>
          </v-row>
        </div>

        <!-- Description Section -->
        <div class="section hover-section">
          <h2 class="section-header">Description</h2>
          <v-row>
            <v-col md="12">
              <div class="flexBox iconText">
                <div>
                  <span>Property Description</span>
                  <h2>
                    <v-textarea
                      v-model="property.propertyDescription"
                      outlined
                      class="hover-field"
                      rows="5"
                    />
                  </h2>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row justify="center" class="mt-4">
            <v-col cols="auto">
              <v-btn
                class="update-btn"
                @click="updatePropertyAct"
                :loading="loadingUpdate"
                color="primary"
              >
                Update Description
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </div>
    </form>
    <!-- Snackbar without Close Button, Auto-Close after 3 Seconds -->
    <v-snackbar v-model="snackbar" :timeout="3000">
      {{ message }}
    </v-snackbar>
    <!-- Property Selection Dialog -->
    <v-dialog v-model="showPropertyDialog" max-width="500px">
      <v-card>
        <v-card-title>Select Property</v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item
              v-for="(property, index) in propertyList"
              :key="index"
              @click="selectProperty(property)"
              :class="{ 'blue lighten-4': property.selected }"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ property.address1 }}, {{ property.postcode }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import Loading from "@/components/dashboard/Loading";
import UploadImage from "@/components/dashboard/ImageUpload";
import { mapActions, mapGetters } from "vuex";
import draggable from "vuedraggable";

export default {
  name: "SinglePropertyMarket",
  components: {
    Loading,
    UploadImage,
    draggable
  },
  data: () => ({
    property: {
      images: [],
      towns: [],
      nearestSchool: [],
      nearestStation: [],
      streetComparable: [],
      marketComparable: [],
      saleComparable: [],
      rentComparable: [],
      benefits: [],
      refurbishments: [],
      reservationPrice: 100,
      legal: 1500,
      price: 0,
      depositeProperty: 0,
      setupFeeValuation: 500,
      currentRent: 0,
      averageRent: 0,
      management: 0,
      opex: 0,
      stampDuty: 0,
      rentGrossYield: 0,
      brokerage: 0,
      investment: 0,
      discountToMarket: 0,
      discountLabel: "0% Discount",
      mortgage: 0,
      netCashflow: 0,
      annualCashflow: 0,
      condition: "",
      tenureType: "",
      houseSubtype: "",
      strategy: "",
      occupancy: "",
      bedrooms: "",
      epc: "",
      floorsqft: "",
      floodRisk: "",
      propertyDescription: ""
    },
    loading: true,
    loadingUpdate: false,
    loadingImagesUpdate: false, // New Loading State for Images Update
    loadingSchoolData: false,
    loadingEPCData: false,
    loadingFloodRiskData: false,
    loadingNearestStations: false,
    loadingSoldPricesData: false,
    loadingAreaSoldPricesData: false,
    loadingForSaleData: false,
    loadingForRentData: false,
    corsAuthorizationRequired: false,
    schoolDataFetchFailed: false,
    snackbar: false,
    message: "",
    showPropertyDialog: false,
    propertyList: [],
    showLockModal: false,
    lockPassword: '',
    fieldsLocked: true,
    imagesInitialized: false,
    schoolDataEditable: false,
    soldPricesDataEditable: false,
    areaSoldPricesDataEditable: false,
    forSaleDataEditable: false,
    forRentDataEditable: false,
    nearestStationsEditable: false,
  }),
  computed: {
    ...mapGetters(["allPublishedProperty"]),

    addressFields() {
      return [
        { label: "Door Number", model: "doorNumber", type: "text", locked: false },
        { label: "Address", model: "fullAddress", type: "text", locked: false },
        { label: "Region", model: "region", type: "text", locked: false },
        { label: "Town", model: "town", type: "select", items: this.property.towns, locked: false },
      ];
    },

    financialFields() {
      return [
        { label: "Purchase Price", model: "price", type: "text", locked: false },
        { label: "Zoopla Valuation", model: "estimatedValue", type: "text", locked: false },
        { label: "Current Rent", model: "currentRent", type: "text", locked: false },
        { label: "Estimated Rent", model: "averageRent", type: "text", locked: false },
        { label: "Deposit", model: "depositeProperty", type: "text", locked: true },
        { label: "Investment", model: "investment", type: "text", locked: true },
        { label: "Mortgage Label", model: "mortgageLabel", type: "text", locked: true },
        { label: "Mortgage", model: "mortgage", type: "text", locked: true },
        { label: "Reservation Price", model: "reservationPrice", type: "text", locked: true },
        { label: "Brokerage (1% of borrowing)", model: "brokerage", type: "text", locked: true },
        { label: "Management Fees", model: "management", type: "text", locked: true },
        { label: "Operating Expenses", model: "opex", type: "text", locked: true },
        { label: "Stamp Duty", model: "stampDuty", type: "text", locked: true },
        { label: "Legal Fees", model: "legal", type: "text", locked: true },
        { label: "Valuation & Mortgage Set Up Fees", model: "setupFeeValuation", type: "text", locked: true },
        { label: "Discount Label", model: "discountLabel", type: "text", locked: true },
        { label: "Discount", model: "discountToMarket", type: "text", locked: true },
        { label: "Net cashflow", model: "netCashflow", type: "text", locked: true },
        { label: "Net annual cashflow", model: "annualCashflow", type: "text", locked: true },
        { label: "Gross yield", model: "rentGrossYield", type: "text", locked: true },
      ];
    },

    
    otherFields() {
      return [
        { 
          label: "Freehold/Leasehold", 
          model: "tenureType", 
          type: "select", 
          items: [
            { text: "Freehold", value: "Freehold" },
            { text: "Leasehold", value: "Leasehold" }
          ]
        },
        { 
          label: "Property type", 
          model: "houseSubtype", 
          type: "select", 
          items: [
            { text: "Apartment", value: "Apartment" },
            { text: "Apartment Block", value: "Apartment Block" },
            { text: "Bungalow", value: "Bungalow" },
            { text: "Detached House", value: "Detached House" },
            { text: "Mixed-Commercial and Resi", value: "Mixed- Commercial and Resi" },
            { text: "Portfolio", value: "Portfolio" },
            { text: "Semi Commercial", value: "Semi Commercial" },
            { text: "Semi-Detached House", value: "Semi-Detached House" },
            { text: "Terraced House", value: "Terraced House" }
          ]
        },
        { 
          label: "Strategy", 
          model: "strategy", 
          type: "select", 
          items: [
            { text: "Buy To Let", value: "Buy To Let" },
            { text: "Development Opportunity", value: "Development Opportunity" },
            { text: "HMO", value: "HMO" },
            { text: "Housing Association", value: "Housing Association" },
            { text: "Portfolio", value: "Portfolio" },
            { text: "Serviced Accommodation", value: "Serviced Accommodation" }
          ],
          onChange: this.handleStrategyChange  // Added Line
        },
        { 
          label: "Occupancy", 
          model: "occupancy", 
          type: "select", 
          items: [
            { text: "Tenanted", value: "Tenanted" },
            { text: "Vacant", value: "Vacant" }
          ]
        },
        { label: "Bedrooms", model: "bedrooms", type: "text" },
        { 
          label: "Condition", 
          model: "condition", 
          type: "select", 
          items: [
            { text: "Good", value: "Good" },
            { text: "Fair", value: "Fair" },
            { text: "Needs Minor Work", value: "Needs Minor Work" },
            { text: "Needing Refurbishment", value: "Needing Refurbishment" }
          ]
        },
        { label: "EPC Rating", model: "epc", type: "text" },
        { label: "Property Size", model: "floorsqft", type: "text" },
        { label: "Flood Risk", model: "floodRisk", type: "text" }
      ];
    },

    dealBenefitText() {
      let benefit = ['Off Market Opportunity'];

      // Check for discount
      if (this.property.discountToMarket > 0) {
        benefit.push(`${this.property.discountToMarket}% Discount`);
      } else {
        benefit.push(this.property.occupancy === 'Tenanted' ? 'Property is Tenanted' : 'Great Investment Area');
      }

      // Add yield information
      const yieldValue = parseFloat(this.property.rentGrossYield).toFixed(2);
      if (this.property.strategy === 'Housing Association') {
        benefit.push(`Net Yield ${yieldValue}%`);
      } else {
        benefit.push(`Gross Yield ${yieldValue}%`);
      }

      return benefit;
    }
  },
  methods: {
    ...mapActions([
      "getPublishedProperty",
      "putPublishedProperty",
      "deleteImage"
    ]),

    clearSchoolData() {
      this.property.nearestSchool = this.property.nearestSchool.map(() => ({ school: '', distance: '' }));
      this.schoolDataEditable = true;
      this.snackbar = true;
      this.message = "School data cleared. Fields are now editable.";
    },

    clearSoldPricesData() {
      this.property.streetComparable = this.property.streetComparable.map(() => '');
      this.soldPricesDataEditable = true;
      this.snackbar = true;
      this.message = "Sold prices data cleared. Fields are now editable.";
    },

    clearAreaSoldPricesData() {
      this.property.marketComparable = this.property.marketComparable.map(() => '');
      this.areaSoldPricesDataEditable = true;
      this.snackbar = true;
      this.message = "Area sold prices data cleared. Fields are now editable.";
    },

    clearForSaleData() {
      this.property.saleComparable = this.property.saleComparable.map(() => '');
      this.forSaleDataEditable = true;
      this.snackbar = true;
      this.message = "For sale data cleared. Fields are now editable.";
    },

    clearForRentData() {
      this.property.rentComparable = this.property.rentComparable.map(() => '');
      this.forRentDataEditable = true;
      this.snackbar = true;
      this.message = "For rent data cleared. Fields are now editable.";
    },

    clearNearestStations() {
      this.property.nearestStation = this.property.nearestStation.map(() => ({ station: '', distance: '' }));
      this.nearestStationsEditable = true;
      this.snackbar = true;
      this.message = "Nearest stations data cleared. Fields are now editable.";
    },

    // Format currency helper
    formatCurrency(value) {
      if (value === null || value === undefined) return '';
      return '£' + Number(value).toLocaleString('en-GB', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    },

    // Handle strategy change
    handleStrategyChange(newValue) {
      this.property.strategy = newValue;
      console.log("Strategy changed to:", newValue);
      this.calculateOperatingExpenses();
      this.calculateNetCashflow();
      this.calculateAnnualCashflow();
    },

    // Fetch and update images automatically
    async refetchImages() {
      try {
        await this.getPublishedProperty(this.$route.params.id);
        this.property.images = this.allPublishedProperty.results.images;
        this.snackbar = true;
        this.message = "Images Loaded Successfully";
      } catch (error) {
        console.error("Error Loading Images", error);
        this.snackbar = true;
        this.message = "Error Loading Images";
      }
    },

    // Update Images Action
    async updateImagesAct() {
      this.loadingImagesUpdate = true;
      try {
        await this.putPublishedProperty(this.property);
        this.snackbar = true;
        this.message = "Images Updated Successfully";
        // Optionally refetch images to ensure synchronization
        await this.refetchImages();
      } catch (error) {
        console.error("Error Updating Images", error);
        this.snackbar = true;
        this.message = "Error Updating Images";
      } finally {
        this.loadingImagesUpdate = false;
      }
    },

    // Update Data

    async updatePropertyAct() {
    this.loadingUpdate = true;
    try {
      await this.putPublishedProperty(this.property);
      this.snackbar = true;
      this.message = "Property Updated Successfully";
    } catch (error) {
      console.error("Error Updating Property", error);
      this.snackbar = true;
      this.message = "Error Updating property";
    } finally {
      this.loadingUpdate = false;
    }
  },

    // Handle drag end event if needed
    async onDragEnd() {
      console.log("Images Rearranged");
      await this.updateImagesAct(); // Automatically update images after rearranging
    },

    // Method to delete image
    async deleteImageAct(id) {
      try {
        await this.deleteImage(id);
        this.property.images = this.property.images.filter(image => image.id !== id);
        this.snackbar = true;
        this.message = "Image Deleted Successfully";
      } catch (error) {
        console.error("Error Deleting Image", error);
        this.snackbar = true;
        this.message = "Error Deleting Image";
      }
    },

    // Refetch data method
    async refetchData() {
      try {
        await this.getPublishedProperty(this.$route.params.id);
        this.property = this.allPublishedProperty.results;
        this.setDefaultValues();
        this.calculateManagementFees();
        this.property.opex = 0; // Set opex to 0
        this.property.mortgageLabel = "N/A";
        this.calculateStampDuty();
        this.calculateGrossYield();
        this.calculateInvestment();
        this.calculateDiscount();
        this.calculateMortgage();
        this.calculateNetCashflow();
        this.calculateAnnualCashflow();
        this.snackbar = true;
        this.message = "Data refreshed successfully.";
      } catch (error) {
        console.error("Error refetching data", error);
        this.snackbar = true;
        this.message = "Error refetching data.";
      }
    },

    // Unlock/Lock Fields
    unlockFields() {
      console.log('Unlock/Lock method called');
      if (this.fieldsLocked) {
        // Currently locked, trying to unlock
        if (this.lockPassword === '@Dylan143') {
          console.log('Correct password, unlocking');
          this.fieldsLocked = false;
          this.showLockModal = false;
          this.lockPassword = '';
          this.snackbar = true;
          this.message = "Fields unlocked";
        } else {
          console.log('Incorrect password');
          this.snackbar = true;
          this.message = "Incorrect password";
        }
      } else {
        // Currently unlocked, locking again
        console.log('Locking fields');
        this.fieldsLocked = true;
        this.snackbar = true;
        this.message = "Data Now Locked";
      }
    },
    
    async fetchForSaleData() {
  this.loadingForSaleData = true;
  this.forSaleDataFetchFailed = false;
  console.log("Fetching for sale - within the area data...");
  const address = this.property.fullAddress;
  let postcode = address.split(', ').pop().trim().replace(' ', '-').toLowerCase();
  const radiusValues = [0, 0.25, 0.5, 1, 3, 5];
  let currentRadiusIndex = 0;
  let listings = [];

  try {
    // Use a public CORS proxy
    const corsProxy = 'https://api.codetabs.com/v1/proxy?quest=';

    // Fetch location ID
    const housePricesUrl = `https://www.rightmove.co.uk/house-prices/${postcode}.html`;
    const housePricesResponse = await fetch(corsProxy + encodeURIComponent(housePricesUrl));
    const housePricesHtml = await housePricesResponse.text();
    const match = housePricesHtml.match(/__PRELOADED_STATE__ = ({.*?})</);
    if (!match) {
      throw new Error('Unable to find preloaded state data');
    }
    const preloadedState = JSON.parse(match[1]);
    const locationId = preloadedState.searchLocation.locationId;

    const bedroomCount = parseInt(this.property.bedrooms) || 0;
    const bedroomFilter = bedroomCount > 0 ? `&minBedrooms=${bedroomCount}&maxBedrooms=${bedroomCount}` : '';

    // Fetch listings
    while (listings.length < 3 && currentRadiusIndex < radiusValues.length) {
      const radius = radiusValues[currentRadiusIndex];
      const listingsUrl = `https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=POSTCODE^${locationId}&sortType=6&viewType=LIST&channel=BUY&index=0&radius=${radius}${bedroomFilter}`;
      
      console.log(`Fetching for sale listings (radius ${radius} miles):`, listingsUrl);
      const listingsResponse = await fetch(corsProxy + encodeURIComponent(listingsUrl));
      const listingsHtml = await listingsResponse.text();
      
      const parser = new DOMParser();
      const doc = parser.parseFromString(listingsHtml, 'text/html');
      
      doc.querySelectorAll('.propertyCard').forEach((card) => {
        if (listings.length < 3) {
          const address = card.querySelector('.propertyCard-address')?.textContent.trim();
          const price = card.querySelector('.propertyCard-priceValue')?.textContent.trim();
          if (address && price) {
            listings.push(`${address} - ${price}`);
          }
        }
      });
      
      currentRadiusIndex++;
    }

    if (listings.length === 0) {
      throw new Error('No listings found');
    }

    // Update the component's data property
    this.property.saleComparable = listings;
    console.log("For sale data fetched successfully:", this.property.saleComparable);
    this.snackbar = true;
    const radius = radiusValues[currentRadiusIndex - 1];
    this.message = `For Sale Data Fetched Successfully at a ${radius} Mile Radius`;
  } catch (error) {
    console.error('Error fetching for sale area data:', error);
    this.snackbar = true;
    this.message = "Failed to fetch for sale area data. You can now enter data manually.";
    this.forSaleDataFetchFailed = true;
    this.property.saleComparable = ['', '', ''];
  } finally {
    // Ensure the loading indicator is turned off
    this.loadingForSaleData = false;
    // Make the fields editable in case of failure
    this.forSaleDataEditable = this.forSaleDataFetchFailed;
  }
},


async fetchForRentData() {
  this.loadingForRentData = true;
  this.forRentDataFetchFailed = false;
  console.log("Fetching for rent - within the area data...");
  
  const address = this.property.fullAddress;
  let postcode = address.split(', ').pop().trim().replace(' ', '-').toLowerCase();

  const radiusValues = [0, 0.25, 0.5, 1, 3, 5];
  let currentRadiusIndex = 0;
  let listings = [];

  try {
    const corsProxy = 'https://api.codetabs.com/v1/proxy?quest=';

    // Fetch location ID
    const housePricesUrl = `https://www.rightmove.co.uk/house-prices/${postcode}.html`;
    const housePricesResponse = await fetch(corsProxy + encodeURIComponent(housePricesUrl));
    const housePricesHtml = await housePricesResponse.text();

    const match = housePricesHtml.match(/__PRELOADED_STATE__ = ({.*?})</);
    if (!match) {
      throw new Error('Unable to find preloaded state data');
    }
    const preloadedState = JSON.parse(match[1]);
    const locationId = preloadedState.searchLocation.locationId;

    const bedroomCount = parseInt(this.property.bedrooms) || 0;
    const bedroomFilter = bedroomCount > 0 ? `&minBedrooms=${bedroomCount}&maxBedrooms=${bedroomCount}` : '';
    const bedroomText = bedroomCount > 0 ? `${bedroomCount} Bedrooms` : 'Any Bedrooms';

    // Fetch rental listings
    while (listings.length < 3 && currentRadiusIndex < radiusValues.length) {
      const radius = radiusValues[currentRadiusIndex];
      const listingsUrl = `https://www.rightmove.co.uk/property-to-rent/find.html?locationIdentifier=POSTCODE^${locationId}&sortType=6&viewType=LIST&channel=RENT&index=0&radius=${radius}${bedroomFilter}&propertyTypes=&includeLetAgreed=false&mustHave=&dontShow=&furnishTypes=&keywords=`;

      console.log(`Fetching rental listings from URL (radius ${radius} miles):`, listingsUrl);
      const listingsResponse = await fetch(corsProxy + encodeURIComponent(listingsUrl));
      const listingsHtml = await listingsResponse.text();

      const parser = new DOMParser();
      const doc = parser.parseFromString(listingsHtml, 'text/html');

      doc.querySelectorAll('.propertyCard').forEach((card) => {
        if (listings.length < 3) {
          const address = card.querySelector('.propertyCard-address')?.textContent.trim();
          const price = card.querySelector('.propertyCard-priceValue')?.textContent.trim();

          if (address && price) {
            listings.push(`${address} - ${price} - ${bedroomText}`);
          }
        }
      });

      currentRadiusIndex++;
    }

    if (listings.length === 0) {
      throw new Error('No rental listings found');
    }

    // Update the component's data property
    this.property.rentComparable = listings;
    console.log("For rent data fetched successfully:", this.property.rentComparable);
    this.snackbar = true;
    const radius = radiusValues[currentRadiusIndex - 1];
    this.message = `For Rent Data Fetched Successfully at a ${radius} Mile Radius`;
  } catch (error) {
    console.error('Error fetching for rent area data:', error);
    this.snackbar = true;
    this.message = "Failed to fetch for rent area data. You can now enter data manually.";
    this.forRentDataFetchFailed = true;
    this.property.rentComparable = ['', '', ''];
  } finally {
    // Ensure the loading indicator is turned off
    this.loadingForRentData = false;
    // Make the fields editable in case of failure
    this.forRentDataEditable = this.forRentDataFetchFailed;
  }
},
    // Fetch Area Sold Prices Data
    async fetchAreaSoldPricesData() {
      this.loadingAreaSoldPricesData = true;
      console.log("Fetching area sold prices data...");
      const address = this.property.fullAddress;
      let fullPostcode = address.split(', ').pop().trim();
      let postcode = fullPostcode.split(' ')[0]; // Take only the first part of the postcode
      
      console.log("Extracted postcode area:", postcode);
      
      try {
        const url = `https://api.propertydata.co.uk/sold-prices?key=PWQYTWQ1NQ&postcode=${postcode}&max_age=84`;
        console.log("API URL:", url);
        
        const response = await fetch(url);
        console.log("Response status:", response.status);
        
        const data = await response.json();
        console.log("API response:", data);
        
        if (data.status === 'success' && data.data && data.data.raw_data) {
          // Sort the data by date in descending order
          const sortedData = data.data.raw_data.sort((a, b) => new Date(b.date) - new Date(a.date));
          
          // Take the three newest entries
          const newestEntries = sortedData.slice(0, 3);
          
          // Format the data
          this.property.marketComparable = newestEntries.map(entry => 
            `${entry.address} - £${entry.price.toLocaleString()} - ${entry.date}`
          );
          
          console.log("Area sold prices data fetched successfully:", this.property.marketComparable);
          this.snackbar = true;
          this.message = "Area sold prices data fetched successfully";
        } else {
          console.error('Failed to fetch area sold prices data:', data);
          this.snackbar = true;
          this.message = "Failed to fetch area sold prices data: " + (data.message || 'Unknown error');
        }
      } catch (error) {
        console.error('Error fetching area sold prices data:', error);
        this.snackbar = true;
        this.message = "Error fetching area sold prices data: " + error.message;
      } finally {
        this.loadingAreaSoldPricesData = false;
        this.areaSoldPricesDataEditable = false;
      }
    },

    async fetchNearestStations() {
      this.loadingNearestStations = true;
      console.log("Fetching nearest stations...");
      const address = this.property.fullAddress;
      let postcode = address.split(', ').pop().trim().replace(/\s/g, '');
      
      console.log("Extracted postcode:", postcode);
      
      try {
        // Step 1: Get Latitude and Longitude from Postcodes.io
        const geoResponse = await fetch(`https://api.postcodes.io/postcodes/${postcode}`);
        const geoData = await geoResponse.json();
        
        if (geoData.status !== 200) {
          throw new Error('Failed to fetch geolocation data');
        }
        
        const { latitude, longitude } = geoData.result;
        
        console.log("Geolocation:", latitude, longitude);
        
        // Step 2: Get Nearest Stations from TransportAPI
        const transportUrl = `https://transportapi.com/v3/uk/places.json?app_id=a90987e1&app_key=584c7eb1e8ca20e41769d9b18dc043cd&lat=${latitude}&lon=${longitude}&type=train_station&rpp=3`;
        const transportResponse = await fetch(transportUrl);
        const transportData = await transportResponse.json();
        
        console.log("Transport API response:", transportData);

        if (!transportData.member || transportData.member.length === 0) {
          throw new Error('No stations found');
        }
        
        // Map the stations data to the desired format (limit to 3)
        this.property.nearestStation = transportData.member.slice(0, 3).map(station => ({
          station: station.name,
          distance: this.convertMetresToMiles(station.distance)
        }));
        
        console.log("Nearest stations data fetched successfully:", this.property.nearestStation);
        this.snackbar = true;
        this.message = "Nearest stations data fetched successfully";
      } catch (error) {
        console.error('Error fetching nearest stations data:', error);
        this.snackbar = true;
        this.message = "Error fetching nearest stations data: " + error.message;
      } finally {
        this.loadingNearestStations = false;
        this.nearestStationsEditable = false;
      }
    },

    // Helper method to convert metres to miles
    convertMetresToMiles(metres) {
      const miles = metres / 1609.344;
      return miles.toFixed(2) + " Miles";
    },

    //Sold Prices
    async fetchSoldPricesData() {
        this.loadingSoldPricesData = true;
        console.log("Fetching sold prices data...");
        const address = this.property.fullAddress;
        let postcode = address.split(', ').pop().trim();
        
        console.log("Extracted postcode:", postcode);
        
        try {
          const url = `https://api.propertydata.co.uk/sold-prices?key=PWQYTWQ1NQ&postcode=${postcode.replace(/\s/g, '')}&max_age=84`;
          console.log("API URL:", url);
          
          const response = await fetch(url);
          console.log("Response status:", response.status);
          
          const data = await response.json();
          console.log("API response:", data);
          
          if (data.status === 'success' && data.data && data.data.raw_data) {
            // Filter for properties that match the postcode
            const filteredData = data.data.raw_data.filter(entry => entry.address.includes(postcode));
            
            // Sort the filtered data by date in descending order
            const sortedData = filteredData.sort((a, b) => new Date(b.date) - new Date(a.date));
            
            // Take the three newest entries
            const newestEntries = sortedData.slice(0, 3);
            
            // Format the data
            this.property.streetComparable = newestEntries.map(entry => 
              `${entry.address} - £${entry.price.toLocaleString()} - ${entry.date}`
            );
            
            console.log("Sold prices data fetched successfully:", this.property.streetComparable);
            this.snackbar = true;
            this.message = "Sold prices data fetched successfully";
          } else {
            console.error('Failed to fetch sold prices data:', data);
            this.snackbar = true;
            this.message = "Failed to fetch sold prices data: " + (data.message || 'Unknown error');
          }
        } catch (error) {
          console.error('Error fetching sold prices data:', error);
          this.snackbar = true;
          this.message = "Error fetching sold prices data: " + error.message;
        } finally {
          this.loadingSoldPricesData = false;
          this.soldPricesDataEditable = false;
        }
      },
      

    async fetchFloodRiskData() {
        this.loadingFloodRiskData = true;
        console.log("Fetching flood risk data...");
        const address = this.property.fullAddress;
        let postcode = address.split(', ').pop().replace(/\s/g, '+');
        
        console.log("Extracted postcode:", postcode);
        
        try {
          const url = `https://api.propertydata.co.uk/flood-risk?key=PWQYTWQ1NQ&postcode=${postcode}`;
          console.log("API URL:", url);
          
          const response = await fetch(url);
          console.log("Response status:", response.status);
          
          const data = await response.json();
          console.log("API response:", data);
          
          if (data.status === 'success' && data.flood_risk) {
            this.property.floodRisk = data.flood_risk;
            console.log("Flood risk data fetched successfully:", this.property.floodRisk);
            this.snackbar = true;
            this.message = "Flood risk data added successfully";
          } else {
            console.error('Failed to fetch flood risk data:', data);
            this.snackbar = true;
            this.message = "Failed to fetch flood risk data: " + (data.message || 'Unknown error');
          }
        } catch (error) {
          console.error('Error fetching flood risk data:', error);
          this.snackbar = true;
          this.message = "Error fetching flood risk data: " + error.message;
        } finally {
          this.loadingFloodRiskData = false;
        }
      },
      
    async fetchEPCData() {
      this.loadingEPCData = true;
      console.log("Fetching EPC data...");
      const address = this.property.fullAddress;
      let postcode = address.split(', ').pop().replace(/\s/g, '');
      
      console.log("Extracted postcode:", postcode);
      
      try {
        const url = `https://epc.opendatacommunities.org/api/v1/domestic/search?postcode=${postcode}`;
        console.log("API URL:", url);
        
        const response = await fetch(url, {
          headers: {
            'Accept': 'text/csv',
            'Authorization': 'Basic cGF1bEBvcGFyby5jby51azpiZGU4ZTBkOWE4Y2Y3OWE1YWRiNzQ3Mzc4YmIxZmUyNjI3ZmMwMjFl'
          }
        });
        console.log("Response status:", response.status);
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const csvText = await response.text();
        console.log("CSV response:", csvText.substring(0, 200) + "..."); // Log first 200 characters of the response
        const properties = this.parseCSV(csvText);
        
        // Add "No EPC Available" option at the beginning of the properties array
        properties.unshift({ address1: "No EPC Available", postcode: "", isNoEPC: true });
        
        this.showPropertySelectionDialog(properties);
      } catch (error) {
        console.error('Error fetching EPC data:', error);
        this.snackbar = true;
        this.message = "Error fetching EPC data: " + error.message;
      } finally {
        this.loadingEPCData = false;
      }
    },
    
    parseCSV(csvText) {
      const lines = csvText.split('\n').filter(line => line.trim() !== '');
      const headers = this.parseCsvLine(lines[0]);

      const properties = lines.slice(1).map(line => {
        const values = this.parseCsvLine(line);
        return headers.reduce((obj, header, index) => {
          obj[header.trim()] = values[index] || '';
          return obj;
        }, {});
      }).filter(prop => prop['address1'] && prop['address1'].trim() !== '');

      return properties.sort((a, b) => {
        const addressA = a['address1'].match(/\d+/) ? parseInt(a['address1'].match(/\d+/)[0]) : 0;
        const addressB = b['address1'].match(/\d+/) ? parseInt(b['address1'].match(/\d+/)[0]) : 0;
        return addressA - addressB;
      });
    },

    showPropertySelectionDialog(properties) {
      this.propertyList = properties;
      this.showPropertyDialog = true;
    },

  parseCsvLine(line) {
    const result = [];
    let startValueIndex = 0;
    let inQuotes = false;

    for (let i = 0; i < line.length; i++) {
      if (line[i] === '"') {
        inQuotes = !inQuotes;
      } else if (line[i] === ',' && !inQuotes) {
        result.push(line.slice(startValueIndex, i).replace(/^"|"$/g, '').trim());
        startValueIndex = i + 1;
      }
    }

    result.push(line.slice(startValueIndex).replace(/^"|"$/g, '').trim());
    return result;
  },

  showPropertySelectionDialog(properties) {
      this.propertyList = properties;
      this.showPropertyDialog = true;
    },

    selectProperty(property) {
      if (property.isNoEPC) {
        this.handleNoEPC();
      } else {
        this.updateEPCData(property);
      }
      this.showPropertyDialog = false;
    },

    handleNoEPC() {
    this.property.epc = "No EPC Available";
    this.property.floorsqft = "";
    this.makePropertySizeEditable();
  },

  makePropertySizeEditable() {
  const index = this.otherFields.findIndex(field => field.model === 'floorsqft');
  if (index !== -1) {
    this.$set(this.otherFields[index], 'readonly', false);
  }
},

makePropertySizeReadOnly() {
  const index = this.otherFields.findIndex(field => field.model === 'floorsqft');
  if (index !== -1) {
    this.$set(this.otherFields[index], 'readonly', true);
  }
},
    
    updateEPCData(selectedProperty) {
    console.log('Selected Property Data:', selectedProperty); // For debugging
    this.makePropertySizeReadOnly();

    const inspectionYear = this.getYear(selectedProperty['inspection-date']);
    const currentRating = selectedProperty['current-energy-rating'];
    const currentEfficiency = selectedProperty['current-energy-efficiency'];
    const potentialRating = selectedProperty['potential-energy-rating'];
    const potentialEfficiency = selectedProperty['potential-energy-efficiency'];

    this.property.epc = this.formatEPCRating(inspectionYear, currentEfficiency, currentRating, potentialEfficiency, potentialRating);

    // Handle Property Size calculation and formatting
    const squareMeters = parseFloat(selectedProperty['total-floor-area']);
    if (!isNaN(squareMeters)) {
      const squareFeet = Math.ceil(squareMeters * 10.76391);
      this.property.floorsqft = `${squareFeet} Sq. Ft. (${squareMeters} Sq.M.)`;
    } else {
      this.property.floorsqft = 'N/A';
    }
  },

  formatEPCRating(year, currentEff, currentRating, potentialEff, potentialRating) {
    return `(${year || 'N/A'}) ${currentEff || 'N/A'} (${currentRating || 'N/A'}) Potential ${potentialRating || 'N/A'} (${potentialEff || 'N/A'})`;
  },

  getYear(dateString) {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return isNaN(date.getFullYear()) ? 'N/A' : date.getFullYear();
  },

  formatEPCRating(year, currentEff, currentRating, potentialEff, potentialRating) {
    return `(${year}) ${currentEff} (${currentRating}) Potential ${potentialRating} (${potentialEff})`;
  },

  getYear(dateString) {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return isNaN(date.getFullYear()) ? 'N/A' : date.getFullYear();
  },

  findValue(obj, key) {
    const lowerKey = key.toLowerCase();
    for (let k in obj) {
      if (k.toLowerCase().includes(lowerKey)) {
        return obj[k] || 'N/A';
      }
    }
    return 'N/A';
  },
  formatAddress(property) {
    return `${property.address1}, ${property.postcode}`.trim();
    },

    async fetchSchoolData() {
      this.loadingSchoolData = true;
      this.schoolDataFetchFailed = false;
      console.log("Fetching school data...");
      const address = this.property.fullAddress;
      let postcode = address.split(', ').pop().replace(/\s/g, '');
      
      console.log("Extracted postcode:", postcode);
      
      try {
        const url = `https://api.propertydata.co.uk/schools?key=PWQYTWQ1NQ&postcode=${postcode}`;
        console.log("API URL:", url);
        
        const response = await fetch(url);
        console.log("Response status:", response.status);
        
        const data = await response.json();
        console.log("API response:", data);
        
        if (data.status === 'success' && data.data && data.data.state && data.data.state.nearest) {
          const nearestSchools = data.data.state.nearest.slice(0, 3);
          this.property.nearestSchool = nearestSchools.map(school => ({
            school: school.name,
            distance: this.formatDistance(school.distance)
          }));
          console.log("School data fetched successfully:", this.property.nearestSchool);
          this.snackbar = true;
          this.message = "School data fetched successfully";
        } else {
          throw new Error(data.message || 'Unknown error');
        }
      } catch (error) {
        console.error('Error fetching school data:', error);
        this.snackbar = true;
        this.message = "Failed to fetch school data. You can now enter data manually.";
        this.schoolDataFetchFailed = true;
        
        // Initialize empty fields for manual entry
        this.property.nearestSchool = [
          { school: '', distance: '' },
          { school: '', distance: '' },
          { school: '', distance: '' }
        ];
      } finally {
        this.loadingSchoolData = false;
        this.schoolDataEditable = false;
      }
    },

  formatDistance(distance) {
    let formattedDistance;
    if (typeof distance === 'number') {
      formattedDistance = distance.toFixed(2);
    } else if (typeof distance === 'string') {
      const numDistance = parseFloat(distance);
      formattedDistance = isNaN(numDistance) ? distance : numDistance.toFixed(2);
    } else {
      formattedDistance = 'N/A';
    }
    return formattedDistance + ' Miles';
  },

    formatDistance(distance) {
      let formattedDistance;
      if (typeof distance === 'number') {
        formattedDistance = distance.toFixed(2);
      } else if (typeof distance === 'string') {
        const numDistance = parseFloat(distance);
        formattedDistance = isNaN(numDistance) ? distance : numDistance.toFixed(2);
      } else {
        formattedDistance = 'N/A';
      }
      return formattedDistance + ' Miles';
    },
    parseValue(value) {
      if (typeof value === 'string') {
        return parseFloat(value.replace(/[^0-9.-]+/g, ''));
      }
      return value;
    },
    numberFormat(givenNumber) {
      let internationalNumberFormat = new Intl.NumberFormat("en-US");
      return internationalNumberFormat.format(givenNumber);
    },
    async updateImagesAct() {
      this.loadingImagesUpdate = true;
      try {
        // Only update the backend if this is not the initial load
        if (this.imagesInitialized) {
          await this.putPublishedProperty(this.property);
        }
        await this.refetchImages();
        this.imagesInitialized = true; // Mark that images have been initialized
        this.snackbar = true;
        this.message = "Images Updated Successfully";
      } catch (error) {
        console.error("Error Updating Images", error);
        this.snackbar = true;
        this.message = "Error Updating Images";
      } finally {
        this.loadingImagesUpdate = false;
      }
    },
    async deleteImageAct(id) {
      await this.deleteImage(id);
      this.property.images = this.property.images.filter(image => image.id !== id);
      this.snackbar = true;
      this.message = "Image Deleted";
    },
    async refetchData() {
      await this.getPublishedProperty(this.$route.params.id);
      this.property = this.allPublishedProperty.results;
      this.setDefaultValues();
    },
    setDefaultValues() {
      if (!this.property.reservationPrice) {
        this.property.reservationPrice = 100;
      }
      if (!this.property.legal) {
        this.property.legal = 2000;
      }
      if (!this.property.mortgageLabel) {
        this.property.mortgageLabel = "N/A";
      }
      if (!this.property.depositeProperty) {
        this.property.depositeProperty = 0;
      }
      if (!this.property.setupFeeValuation) {
        this.property.setupFeeValuation = 500;
      }
      if (!this.property.management) {
        this.property.management = 0;
      }
      if (!this.property.opex) {
        this.property.opex = 0;
      }
      if (!this.property.stampDuty) {
        this.property.stampDuty = 0;
      }
      if (!this.property.rentGrossYield) {
        this.property.rentGrossYield = 0;
      }
      if (!this.property.brokerage) {
        this.property.brokerage = 0;
      }
      if (!this.property.investment) {
        this.property.investment = 0;
      }
      if (!this.property.discountToMarket) {
        this.property.discountToMarket = "0%";
      }
      if (!this.property.discountLabel) {
        this.property.discountLabel = "0% Discount";
      }
    },
    getFieldValue(model) {
      const value = this.property[model];
      if (model === 'rentGrossYield') {
        return value ? value.toFixed(2) + '%' : '';
      }
      if (model === 'discountLabel') {
        return value;
      }
      if (model === 'discountToMarket') {
        return value ? value.toFixed(2) + '%' : '';
      }
      if (model === 'mortgageLabel') {
        return value || 'N/A';  // Return the value as is, or 'N/A' if is False
      }
      if (model === 'opex') {
        return this.formatCurrency(value);
      }
      return this.formatCurrency(value);
    },
    updateFieldValue(model, value) {
      const parsedValue = this.parseValue(value);
      this.property[model] = parsedValue;
      
      if (model === "price") {
        this.property.depositeProperty = parsedValue ? parsedValue / 4 : 0;
        this.property.brokerage = parsedValue ? (parsedValue * 0.75) * 0.01 : 0;
      }
          
      this.calculateManagementFees();
      this.calculateStampDuty();
      this.calculateGrossYield();
      this.calculateInvestment();
      this.calculateDiscount();
      this.calculateMortgage();
      this.calculateNetCashflow();
      this.calculateAnnualCashflow();
    },
    calculateManagementFees() {
      const currentRent = parseFloat(this.property.currentRent) || 0;
      const estimatedRent = parseFloat(this.property.averageRent) || 0;
      const maxRent = Math.max(currentRent, estimatedRent);
      this.property.management = maxRent * 0.10;
    },
   calculateOperatingExpenses() {
      this.property.opex = 0;
      console.log("OpEx set to:", this.property.opex);
    },
    calculateStampDuty() {
      let price = parseFloat(this.property.price) || 0;
      let stampDuty = 0;

      const firstBracket = price > 250000 ? 250000 : price;
      stampDuty += firstBracket * 0.03;

      if (price > 250000) {
        const secondBracket = price > 925000 ? 675000 : price - 250000;
        stampDuty += secondBracket * 0.08;
      }

      if (price > 925000) {
        const thirdBracket = price > 1500000 ? 575000 : price - 925000;
        stampDuty += thirdBracket * 0.13;
      }

      if (price > 1500000) {
        const excess = price - 1500000;
        stampDuty += excess * 0.15;
      }

      this.property.stampDuty = stampDuty;
    },
    calculateGrossYield() {
      const currentRent = parseFloat(this.property.currentRent) || 0;
      const estimatedRent = parseFloat(this.property.averageRent) || 0;
      const maxRent = Math.max(currentRent, estimatedRent);
      const purchasePrice = parseFloat(this.property.price) || 1;
      const grossYield = ((maxRent * 12) / purchasePrice) * 100;
      this.property.rentGrossYield = Math.round(grossYield * 100) / 100;
    },
    calculateInvestment() {
      const deposit = parseFloat(this.property.depositeProperty) || 0;
      const brokerage = parseFloat(this.property.brokerage) || 0;
      const legalFees = parseFloat(this.property.legal) || 0;
      const setupFees = parseFloat(this.property.setupFeeValuation) || 0;
      const stampDuty = parseFloat(this.property.stampDuty) || 0;
      const reservationPrice = parseFloat(this.property.reservationPrice) || 0;
      const additionalCosts = 0;
      const investment = deposit + brokerage + legalFees + setupFees + stampDuty + additionalCosts + reservationPrice;
      this.property.investment = investment;
    },
    calculateDiscount() {
      const estimatedValue = parseFloat(this.property.estimatedValue) || 0;
      const purchasePrice = parseFloat(this.property.price) || 0;
      
      // Calculate the discount percentage
      let discount = ((estimatedValue - purchasePrice) / estimatedValue) * 100;
      
      // Round up to the nearest tenth
      discount = Math.ceil(discount * 10) / 10;
      
      // Ensure the discount is not negative
      discount = Math.max(0, discount);
      
      this.property.discountToMarket = discount;
      this.property.discountLabel = discount.toFixed(1) + "% Discount";
    },

    calculateMortgage() {
      const purchasePrice = parseFloat(this.property.price) || 0;
      const deposit = parseFloat(this.property.depositeProperty) || 0;
      const mortgage = ((purchasePrice - deposit) * 0.055) / 12;
      this.property.mortgage = Math.ceil(Math.max(0, mortgage));
    },
    calculateNetCashflow() {
      const currentRent = parseFloat(this.property.currentRent) || 0;
      const estimatedRent = parseFloat(this.property.averageRent) || 0;
      const maxRent = Math.max(currentRent, estimatedRent);
      const managementFees = parseFloat(this.property.management) || 0;
      const operatingExpenses = parseFloat(this.property.opex) || 0;
      const mortgage = parseFloat(this.property.mortgage) || 0;
      const netCashflow = maxRent - managementFees - operatingExpenses - mortgage;
      this.property.netCashflow = Math.max(0, netCashflow);
    },
    calculateAnnualCashflow() {
      const netCashflow = parseFloat(this.property.netCashflow) || 0;
      const annualCashflow = netCashflow * 12;
      this.property.annualCashflow = Math.max(0, annualCashflow);
    }
  },
  watch: {
    'property.price': function (newPrice) {
      const parsedPrice = this.parseValue(newPrice);
      this.property.depositeProperty = parsedPrice ? parsedPrice / 4 : 0;
      this.property.brokerage = parsedPrice ? (parsedPrice * 0.75) * 0.01 : 0;
      this.calculateStampDuty();
      this.calculateGrossYield();
      this.calculateInvestment();
      this.calculateDiscount();
      this.calculateMortgage();
      this.calculateNetCashflow();
      this.calculateAnnualCashflow();
    },
    'property.currentRent': function() {
      this.calculateManagementFees();
      this.calculateGrossYield();
      this.calculateNetCashflow();
      this.calculateAnnualCashflow();
    },
    'property.averageRent': function() {
      this.calculateManagementFees();
      this.calculateGrossYield();
      this.calculateNetCashflow();
      this.calculateAnnualCashflow();
    },
    'property.strategy': function(newStrategy, oldStrategy) {
      console.log("Strategy watcher triggered. Changed from", oldStrategy, "to", newStrategy);
      this.calculateNetCashflow();
      this.calculateAnnualCashflow();
    },
    'property.depositeProperty': function () {
      this.calculateInvestment();
      this.calculateMortgage();
      this.calculateNetCashflow();
      this.calculateAnnualCashflow();
    },
    'property.brokerage': function () {
      this.calculateInvestment();
    },
    'property.legal': function () {
      this.calculateInvestment();
    },
    'property.setupFeeValuation': function () {
      this.calculateInvestment();
    },
    'property.stampDuty': function () {
      this.calculateInvestment();
    },
    'property.estimatedValue': function () {
      this.calculateDiscount();
    },
     'property.strategy': function(newStrategy, oldStrategy) {
        console.log("Strategy watcher triggered. Changed from", oldStrategy, "to", newStrategy);
        this.calculateOperatingExpenses();
        this.calculateNetCashflow();
        this.calculateAnnualCashflow();
  }
},
  watch: {
  // ... other watchers ...
  

  dealBenefitText: {
    handler() {
      this.property.benefits = this.dealBenefitText;
    },
    immediate: true
  }
},
  async created() {
  await this.getPublishedProperty(this.$route.params.id);
  this.property = this.allPublishedProperty.results;
  this.property.town = this.property.towns.length > 0 ? this.property.towns[0].town : "";
  this.setDefaultValues();
  this.calculateManagementFees();
  this.property.opex = 0; // Set opex to 0
  this.property.mortgageLabel = "N/A";
  this.calculateStampDuty();
  this.calculateGrossYield();
  this.calculateInvestment();
  this.calculateDiscount();
  this.calculateMortgage();
  this.calculateNetCashflow();
  this.calculateAnnualCashflow();
  this.loading = false;
  await this.updateImagesAct();
},
 
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.v-text-field input {
  text-align: right;
}

.section {
  padding: 20px;
  background-color: #2483C5;
  border-radius: 5px;
  margin-bottom: 20px;
  transition: background-color 0.3s, color 0.3s;
  &:hover {
    background-color: #333;
    color: white;

    ::v-deep .v-text-field input,
    ::v-deep .v-select__selection,
    ::v-deep .v-select__selection-text,
    ::v-deep .v-textarea textarea {
      color: white !important;
    }

    ::v-deep .v-text-field .v-label,
    ::v-deep .v-select .v-label {
      color: rgba(255, 255, 255, 1) !important;
    }

    ::v-deep .v-input__slot:before,
    ::v-deep .v-input__slot:after {
      border-color: white !important;
    }
  }
}

.locked-field-section {
  position: relative;
  
  &::before {
    content: '';
    position: absolute;
    top: -20px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 0;
  }

  .section-header,
  ::v-deep .v-input {
    position: relative;
    z-index: 1;
  }
}

.section-header {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
}

.iconText {
  span {
    font-size: 15px;
    display: block;
    margin-bottom: 5px;
  }
}

.images {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  li {margin-right: 10px;
  }
  img {
    width: 200px;
    height: 200px;
    object-fit: cover;
  }
}

.image-item {
  position: relative;
  cursor: move;
}

.closeBtn {
  position: absolute;
  right: 5px;
  top: 5px;
  background: red;
  color: #fff;
  border-radius: 50%;
  padding: 3px;
  cursor: pointer;
  font-size: 14px;
}

.flexBox {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  h2 {
    margin-top: 5px;
  }
}

.fixedLoading {
  position: fixed;
  top: 10px;
  z-index: 999;
  display: flex;
  align-items: center;
  span {
    margin-right: 10px;
  }
}

.width100 {
  width: 100% !important;
  .v-btn {
    width: 80%;
  }
}

.flex-box-space-between {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.uk-sticky {
  margin: 0;
  z-index: 999999;
  width: 200px;
  text-decoration: none;
}

.section {
  position: relative;
  padding-bottom: 60px;

  .update-btn {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333 !important;
    color: rgba(255, 255, 255, 1) !important;
    transition: background-color 0.3s, color 0.3s;
  }

  &:hover {
    .update-btn {
      background-color: #2483C5 !important;
      color: white !important;

      &:hover {
        background-color: #616161 !important; // Slightly lighter grey on hover
      }
    }
  }
}

// Override Vuetify's default button styles
.v-btn.update-btn::before {
  background-color: transparent;
}

.add-data-btn {
  background-color: #333 !important;
  color: rgba(255, 255, 255, 1) !important;
  transition: background-color 0.3s, color 0.3s;
  font-size: 0.8rem;
  padding: 0 12px;
  height: 28px;
  margin-bottom: 16px !important;
  width:125px;

  &:hover {
    background-color: #2483C5 !important;
    color: white !important;
  }
}

.section:hover .add-data-btn {
  background-color: #2483C5 !important;
  color: white !important;
}

.school-section {
  margin-bottom: 16px;
  
}


.iconText {
  span {
    font-size: 15px;
    display: block;
    margin-bottom: 5px;
  }
}

.locked-field {
  background-color: rgba(0, 0, 0, 0.3);
}

.v-text-field.locked-field input {
  color: rgba(0, 0, 0, 0.6);
}



.theme--light.v-input input, .theme--light.v-input textarea {
  color: rgba(0, 0, 0, 0.87);
}

.lock-icon {
  background-color: black !important;
  
  .v-icon {
    color: white !important;
  }
  
  &:hover {
    background-color: #2483C5 !important;
    
    .v-icon {
      color: black !important;
    }
  }
}

.section:hover .lock-icon {
  background-color: #2483C5 !important;
  
  .v-icon {
    color: black !important;
  }
}

.modal-btn {
  background-color: #2483C5 !important;
  color: white !important;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: black !important;
    color: white !important;
  }

  span {
    color: white !important;
  }
}

// Override Vuetify's default hover effect
.v-btn:before {
  opacity: 0 !important;
}

//Spacer 

.deal-benefit-section {
  .deal-benefit-spacer {
    height: 28px; // Adjust this value to match the height of your "Add Data" buttons
    margin-bottom: 16px; // Adjust this to match the margin-bottom of your "Add Data" buttons
  }

  span {
    margin-bottom: 5px;
  }
}

// Ensure consistent spacing for all sections in Additional Details
.flexBox.iconText {
  display: flex;
  flex-direction: column;

  > span {
    font-size: 15px;
    margin-bottom: 5px;
  }

  .v-btn.add-data-btn,
  .deal-benefit-spacer {
    margin-bottom: 16px;
  }
}

//Other Details button

.data-field {
  position: relative;

  .field-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;

    span {
      font-size: 15px;
    }
  }

  .add-data-btn {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 0.7rem;
    padding: 0 8px;
    height: 24px;
    background-color: #424242 !important; // Dark grey background
    color: white !important;
  }

  .v-text-field {
    margin-top: 28px; // Adjust this value to create space for the button
  }
}

// Override Vuetify's default button styles
.v-btn.add-data-btn::before {
  background-color: transparent;
}

.v-btn.add-data-btn:hover {
  background-color: #616161 !important; // Slightly lighter grey on hover
}

.section:hover {
  .theme--light.v-input input,
  .theme--light.v-input textarea {
    color: white !important;
  }
}

.locked-field-container {
  position: relative;
  padding: 10px;
  border-radius: 4px;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 0;
    border-radius: 4px;
  }

  > div {
    position: relative;
    z-index: 1;
  }

  span, .v-input {
    position: relative;
    z-index: 1;
  }
}

.section:hover {
  .locked-field-container::before {
    background-color: rgba(255, 255, 255, 0.1);
  }

  .locked-field-container {
    span, .v-input input {
      color: white !important;
    }
  }
}

.button-group {
  display: flex;
  gap: 2px;
  margin-bottom: 16px;
}

.clear-data-btn {
  background-color: #f44336 !important; /* Red color for clear button */
  color: white !important;
  transition: background-color 0.3s, color 0.3s;
  font-size: 0.8rem;
  padding: 0 12px;
  height: 28px;
  width: 125px;
}

.clear-data-btn:hover {
  background-color: #d32f2f !important; /* Darker red on hover */
}

.section:hover .clear-data-btn {
  background-color: #f44336  !important; /* Even darker red when section is hovered */
}

.section:hover .clear-data-btn:hover {
  background-color: #616161 !important; /* Grey on hover when section is hovered */
}


</style>